<template>
  <nav class="nav">
    <router-link to="/">
      <img
        v-if="this.$route.path === '/contest'"
        src="../assets/images/Sewcase_Logo_White.png"
        id="nav-logo"
        alt="logo"
      />
      <img
        v-else
        src="../assets/images/Sewcase_Logo_Black.png"
        id="nav-logo"
        alt="logo"
      />
    </router-link>
    <div class="nav-main-container">
      <NavigationDesktop />
      <NavigationMobile />

      <div class="nav-lang-switch">
        <div
          :class="[
            'nav-lang-switch-item',
            {
              'nav-lang-switch-item--active': getLanguage === 'de-DE',
            },
          ]"
          @click="switchLanguage('de-DE')"
        >
          DE
        </div>
        <div
          :class="[
            'nav-lang-switch-item',
            {
              'nav-lang-switch-item--active': getLanguage === 'en-US',
            },
          ]"
          @click="switchLanguage('en-US')"
        >
          EN
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import NavigationDesktop from "../components/NavigationDesktop";
import NavigationMobile from "../components/NavigationMobile";

export default {
  name: "vcp-router",
  components: {
    NavigationDesktop,
    NavigationMobile,
  },
  computed: {
    ...mapGetters(["getRoutesEN", "getRoutesDE", "getLanguage"]),
  },
  methods: {
    ...mapMutations(["setLanguage"]),
    sortRoutes() {
      const routes =
        this.getLanguage == "de-DE" ? this.getRoutesDE : this.getRoutesEN;

      var sortedList = routes.sort(function (a, b) {
        return a.position - b.position || a.name?.localeCompare(b.name);
      });

      return sortedList;
    },
    sanitizeRouterLabel(route) {
      if (route === "/" || route === undefined || route == "")
        route = "About Us";

      route = route.replace("-", " ");

      route = route[0].toUpperCase() + route.substring(1);

      return route;
    },
    toggleBurgerMenu() {
      var navBar = document.getElementById("nav-mobile-bar");
      var navIcon = document.getElementById("nav-mobile-icon");
      navBar.classList.toggle("nav-mobile-bar--open");
      navIcon.classList.toggle("nav-mobile-icon--open");
    },
    switchLanguage(lang) {
      this.setLanguage(lang);
    },
  },
};
</script>

<style lang="scss">
nav {
  background: $sewcase-white;
  display: flex;
  justify-content: space-between;
  height: 96px;
  left: 0;
  top: 0;
  padding: 0 $spacing-md * 1.5;
  z-index: 10;

  img {
    height: 72px;
    margin: 10px 0;
  }

  a {
    color: $sewcase-text-dark;
    text-decoration: none;
  }
}

.nav {
  &-main-container {
    display: flex;
    align-items: center;

    @media (min-width: $md) {
      column-gap: 0;
    }
  }

  .nav-lang-switch {
    padding: 0 12px;
    margin-right: -$spacing-md;
    display: flex;
    column-gap: 12px;
    position: absolute;
    right: 128px;

    &:after {
      content: "|";
    }

    @media (min-width: $md) {
      position: initial;

      &:before {
        content: "|";
      }

      &:after {
        content: " ";
      }
    }

    &-item {
      cursor: pointer;

      &--active {
        color: $sewcase-secondary;
      }
    }
  }
}
</style>
